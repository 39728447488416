<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      :add-visible="false"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
<!--        <query-item label="车辆类型">-->
<!--          <a-select v-model="query.kartClassifyId" allowClear placeholder="请选择">-->
<!--            <a-select-option v-for="item in vehicleCategoryList" :key="item.kartClassifyId" :value="item.kartClassifyId">-->
<!--              {{item.kartClassifyName}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </query-item>-->
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { timesCardRuleList, ruleMinShow, timesCardRuleStatus } from '@/api/timesCard';

import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'PriceRuleList',
  mixins: [queryMixin],
  data() {
    return {
      query: {
        kartClassifyId: null,
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '车辆类型名称',
          dataIndex: 'kartClassifyName',
          key: 'kartClassifyName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '车辆类型编码',
          dataIndex: 'kartClassifyCode',
          key: 'kartClassifyCode',
          ellipsis: true,
          width: 200,
        },
        {
          title: '车辆类型规格',
          dataIndex: 'kartClassifySpec',
          key: 'kartClassifySpec',
          ellipsis: true,
          width: 200,
        },
        {
          title: '次卡名称',
          dataIndex: 'timesCardName',
          key: 'timesCardName',
          ellipsis: true,
          width: 240,
        },
        {
          title: '次数',
          dataIndex: 'count',
          key: 'count',
          ellipsis: true,
          width: 120,
        },
        {
          title: '价格',
          dataIndex: 'price',
          key: 'price',
          ellipsis: true,
          width: 120,
        },
        {
          title: '小程序',
          dataIndex: 'minShow',
          key: 'minShow',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="显示" un-checked-children="隐藏" checked={row.minShow === 0} onClick={ () => this.handleMinShow(row)}/>
            );
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '有效时间',
          dataIndex: 'periodType',
          key: 'periodType',
          ellipsis: true,
          width: 240,
          customRender: (text, row) => {
            return (
              <section>{row.periodType === 0 ? '长期有效' : `${row.startDate} - ${row.endDate}`}</section>
            );
          }
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true,
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 120,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children: <section>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
              </section>,
            };
          }
        },
      ],
      dataList: [],
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      timesCardRuleList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.timesCardRuleId;
        });
        this.dataList = list;
        console.log(this.dataList);
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'timesCardPriceEdit',
        params: {
          id: row.timesCardRuleId
        }
      });
    },
    handleMinShow(row) {
      const params = {
        timesCardRuleId: row.timesCardRuleId,
        minShow: row.minShow === 0 ? 1 : 0
      };

      ruleMinShow(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    // 启用或者禁用
    handleStatus(row) {
      const params = {
        timesCardRuleId: row.timesCardRuleId,
        status: row.status === 0 ? 1 : 0
      };
      console.log(params);

      timesCardRuleStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style lang="less" >
  .unuse {
    text-decoration: line-through;
  }
</style>
